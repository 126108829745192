import API from './API';

export const logInService = async (data) => API.post('/auth/login', data);

export const addEmployeeService = async (data) => API.post('/employees', data);
export const updateProfileService = async (data) => API.post('employees/update/profile', data);

export const getAllEmployeeService = async () => API.get('/employees');
export const deleteEmployeeService = async (data) =>
	API.put(`/employees/in-active/${data.id}`, data);
export const getEmployeeDetailService = async (id) => API.get(`/employees/${id}`);
export const updateEmployeeService = async (id, data) => API.put(`/employees/${id}`, data);
export const removeEmployeeImageService = async (id) =>
	API.delete(`/employees/destroy-image/${id}`);
export const removeEmployeeDocumentService = async (id) =>
	API.delete(`/employees/destroy-document/${id}`);
export const deleteDependentService = async (id) => API.delete(`/employees/dependent/${id}`);
export const getCurrentEmployeeService = async () => API.get(`/employees/me`);
export const getEmployeeLineManager = async () => API.get('/employees/line-manager');

//permissions
export const addPermissionService = async (data) => API.post('/permissions', data);
export const getPermissionsService = async () => API.get('/permissions');
export const updatePermissionService = async (id, data) => API.put(`/permissions/${id}`, data);
export const deletePermissionService = async (id) => API.delete(`/permissions/${id}`);

//Roles
export const addRoleService = async (data) => API.post(`/roles`, data);
export const getRoleService = async () => API.get(`/roles`);
export const updateRoleService = async (id, data) => API.put(`/roles/${id}`, data);
export const inactiveRoleService = async (id, data) => API.put(`/roles/inactive/${id}`, data);

export const rolesAndDepertmentService = async () => API.get('/dropdowns');

//attendance
export const fetchEmployeesForAttendance = async (data) => API.post('/attendance/mark', data);
export const markAttendanceService = async (data) => API.post('/attendance', data);
export const getAttendenceService = async (data) => API.post('/attendance/get', data);
export const attendanceDetail = async (data) => API.post(`/attendance/${data?.id}`, data);
export const updateAttendanceService = async (id, data) => API.put(`/attendance/${id}`, data);


//Leaves
export const addLeaveService = async (data) => API.post(`/leaves`, data);
export const getLeaveService = async () => API.get(`/leaves`);
export const getLeaveBalanceService = async (data) => API.post(`/leaves/balance`,data);
export const updateLeaveService = async (id, data) => API.put(`/leaves/${id}`, data);
export const inactiveLeaveService = async (id, data) => API.put(`/leaves/${id}`, data);
export const applyLeaveService = async (data) => API.post(`/request/leaves`, data);
export const getRequestedLeaveService = async () => API.get(`/request/leaves`);
export const updateRequestedLeaveService = async (id, data) =>
	API.put(`/request/leaves/${id}`, data);
export const getEmployeeLeaveService = async (id) => API.post(`/leaves/remaining/info`, {id});
export const getEmployeeRequestedLeaveService = async (data) => {
  return API.post(`/request/leaves/requested`, data);
};
export const deleteMyRequestedLeaveService = async (id) => API.delete(`/request/leaves/${id}`);

//Device
export const addDeviceService = async (data) => API.post(`/deviceinfo`, data);
export const getDeviceService = async () => API.get(`/deviceinfo`);
export const updateDeviceService = async (id, data) => API.put(`/deviceinfo/${id}`, data);
export const deleteDeviceService = async (id, data) => API.delete(`/deviceinfo/${id}`, data);
export const connectDeviceService = async (data) => API.post(`/deviceinfo/connect`, data)
export const disconnectDeviceService = async (data) => API.post(`/deviceinfo/disconnect`, data);

