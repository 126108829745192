import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	attendanceDetail,
	fetchEmployeesForAttendance,
	getAttendenceService,
	markAttendanceService,
	updateAttendanceService,
} from '../../../services/services';

export const addAttendance = createAsyncThunk('addAttendance', async (credentials, thunkAPI) => {
	try {
		const response = await markAttendanceService(credentials);
		return response.data;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const getAttendance = createAsyncThunk('getAttendance', async (credentials, thunkAPI) => {
	try {
		const response = await getAttendenceService(credentials);
		return response.data;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const fetchForAttendance = createAsyncThunk(
	'fetchForAttendance',
	async (credentials, thunkAPI) => {
		try {
			const response = await fetchEmployeesForAttendance(credentials);
			return response.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const updateAttendance = createAsyncThunk(
	'updateAttendance',
	async (credentials, thunkAPI) => {
		try {
			const { id } = credentials;
			const response = await updateAttendanceService(id, credentials);
			return response.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const employeeAttendanceDetail = createAsyncThunk(
	'attendanceDetail',
	async (payload, thunkAPI) => {
		try {
			const response = await attendanceDetail(payload);
			return response.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

const attendanceSlice = createSlice({
	name: 'attendanceSlice',
	initialState: {
		attendance: null,
		isSubmitting: false,
		addingAttendanceError: null,
		attendances: null,
		isLoading: false,
		error: null,
		update: null,
		isUpdating: false,
		updateError: null,
		employees: null,
		isfetch: false,
		attendanceDetail: [],
	},
	reducers: {
		resetAttendance: (state) => {
			state.attendance = null;
			state.isSubmitting = false;
			state.addingAttendanceError = null;
			state.isLoading = false;
			state.error = null;
			state.update = null;
			state.isUpdating = null;
			state.updateError = null;
			state.employees = null;
			state.isfetch = false;
		},

		resetUpdate: (state) => {
			state.update = null;
			state.isUpdating = null;
			state.updateError = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(addAttendance.pending, (state) => {
				state.isSubmitting = true;
				state.error = null;
			})
			.addCase(addAttendance.fulfilled, (state, action) => {
				state.isSubmitting = false;
				state.attendance = action.payload;
				state.error = null;
			})
			.addCase(addAttendance.rejected, (state, action) => {
				state.isSubmitting = false;
				state.attendance = null;
				state.error = action.payload;
			});
		builder
			.addCase(updateAttendance.pending, (state) => {
				state.isUpdating = true;
				state.updateError = null;
			})
			.addCase(updateAttendance.fulfilled, (state, action) => {
				state.isUpdating = false;
				state.update = action.payload;
				state.updateError = null;
			})
			.addCase(updateAttendance.rejected, (state, action) => {
				state.isUpdating = false;
				state.update = null;
				state.updateError = action.payload;
			});
		builder
			.addCase(getAttendance.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(getAttendance.fulfilled, (state, action) => {
				state.isLoading = false;
				state.attendances = action.payload.data;
				state.error = null;
			})
			.addCase(getAttendance.rejected, (state, action) => {
				state.isLoading = false;
				state.attendances = null;
				state.error = action.payload;
			});
		builder
			.addCase(fetchForAttendance.pending, (state) => {
				state.isfetch = true;
				state.error = null;
			})
			.addCase(fetchForAttendance.fulfilled, (state, action) => {
				state.isfetch = false;
				state.employees = action.payload?.data;
				state.error = null;
			})
			.addCase(fetchForAttendance.rejected, (state, action) => {
				state.isfetch = false;
				state.employees = null;
				state.error = action.payload;
			});

		builder
			.addCase(employeeAttendanceDetail.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(employeeAttendanceDetail.fulfilled, (state, action) => {
				state.isLoading = false;
				state.attendanceDetail = action.payload?.data;
				state.error = null;
			})
			.addCase(employeeAttendanceDetail.rejected, (state, action) => {
				state.isLoading = false;
				state.attendanceDetail = [];
				state.error = action.payload;
			});
	},
});

export const { resetAttendance, resetUpdate } = attendanceSlice.actions;
export default attendanceSlice.reducer;
