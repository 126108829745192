export function test() {
	return null;
}

export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	// @ts-ignore
	document.documentElement.setAttribute('os', os);
	return os;
}

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	// @ts-ignore
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const mergeRefs = (refs: any[]) => {
	return (value: any) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

	const color = Math.floor(Math.random() * colors.length);

	return colors[color];
};

export const priceFormat = (price: number) => {
	return price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
};

export const average = (array: any[]) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1: number, value2: number) =>
	Number(((value1 / value2 - 1) * 100).toFixed(2));

export const getFirstLetter = (text: string, letterCount = 2): string =>
	// @ts-ignore
	text
		.toUpperCase()
		.match(/\b(\w)/g)
		.join('')
		.substring(0, letterCount);

export const debounce = (func: (arg0: any) => void, wait = 1000) => {
	let timeout: string | number | NodeJS.Timeout | undefined;

	return function executedFunction(...args: any[]) {
		const later = () => {
			clearTimeout(timeout);
			// @ts-ignore
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};

export const capitalizeFirst = (str: string) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const downloadImage = (imageUrl: string, imageName: string): void => {
	fetch(imageUrl, {
		method: 'GET',
		headers: {},
	})
		.then((response) => {
			response.arrayBuffer().then(function (buffer) {
				const url = window.URL.createObjectURL(new Blob([buffer]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', imageName); //or any other extension
				document.body.appendChild(link);
				link.click();
			});
		})
		.catch((err) => {
			console.log(err);
		});
};

// export function FormatDate(inputDate:any) {
// 	const months = [
// 	  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
// 	];

// 	const dateParts = inputDate.split(' ')[0].split('-');
// 	const formattedDate = `${months[parseInt(dateParts[1], 10) - 1]}-${dateParts[2]}-${dateParts[0]} ${inputDate.split(' ')[1]}`;

// 	const inputDateTime = new Date(inputDate).getTime();
// 	const currentTime = new Date().getTime();
// 	const timeDifference = currentTime - inputDateTime;
// 	const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

// 	let distance = '';
// 	if (daysDifference === 0) {
// 	  const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
// 	  if (hoursDifference === 0) {
// 		const minutesDifference = Math.floor(timeDifference / (1000 * 60));
// 		if (minutesDifference === 0) {
// 		  distance = 'just now';
// 		} else {
// 		  distance = `${minutesDifference} ${minutesDifference === 1 ? 'minute' : 'minutes'} ago`;
// 		}
// 	  } else {
// 		const timeString = new Date(inputDateTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
// 		distance = `${hoursDifference} ${hoursDifference === 1 ? 'hour' : 'hours'} ago (${timeString})`;
// 	  }
// 	} else if (daysDifference === 1) {
// 	  distance = `yesterday (${new Date(inputDateTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false })})`;
// 	} else if (daysDifference > 1 && daysDifference <= 30) {
// 	  distance = `${daysDifference} ${daysDifference === 1 ? 'day' : 'days'} ago`;
// 	} else if (daysDifference > 30 && daysDifference <= 60) {
// 	  distance = 'a month ago';
// 	} else if (daysDifference > 60 && daysDifference <= 365) {
// 	  const monthsAgo = Math.floor(daysDifference / 30);
// 	  distance = `${monthsAgo} ${monthsAgo === 1 ? 'month' : 'months'} ago`;
// 	} else {
// 	  const yearsAgo = Math.floor(daysDifference / 365);
// 	  distance = `${yearsAgo} ${yearsAgo === 1 ? 'year' : 'years'} ago`;
// 	}

// 	return { formattedDate, distance };
//   }

//   // Example usage:
//   const inputDateFromDatabase = '2023-7-26 19:05:12';
//   const { formattedDate, distance } = FormatDate(inputDateFromDatabase);
//   console.log(formattedDate); // Output: "Jul-21-2023 02:05:12"
//   console.log(distance); // Output: "in 2 years"

export function FormatDate(inputDate: any) {
	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];

	const dateParts = inputDate.split(' ')[0].split('-');
	const formattedDate = `${months[parseInt(dateParts[1], 10) - 1]}-${dateParts[2]}-${
		dateParts[0]
	} ${inputDate.split(' ')[1]}`;

	const inputDateTime = new Date(inputDate).getTime();
	const currentTime = new Date().getTime();
	const timeDifference = currentTime - inputDateTime;
	const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

	let distance = '';
	if (daysDifference === 0) {
		distance = 'today';
	} else if (daysDifference === 1) {
		distance = 'yesterday';
	} else if (daysDifference > 1 && daysDifference <= 30) {
		distance = `${daysDifference} days ago`;
	} else if (daysDifference > 30 && daysDifference <= 60) {
		distance = 'a month ago';
	} else if (daysDifference > 60 && daysDifference <= 365) {
		const monthsAgo = Math.floor(daysDifference / 30);
		distance = `${monthsAgo} months ago`;
	} else {
		const yearsAgo = Math.floor(daysDifference / 365);
		distance = `${yearsAgo} years ago`;
	}

	return { formattedDate, distance };
}

export const formatDate = (inputDate: any): string => {
	const date = new Date(inputDate);
	const formattedDate = date.toISOString().split('T')[0]; // Extract YYYY-MM-DD
	return formattedDate;
};

export function convertToMonthName(inputDate: any) {
	const date = new Date(inputDate);
	const options = { year: 'numeric', month: 'long', day: 'numeric' };
	//@ts-ignore
	return date.toLocaleString('en-US', options);
}

export function convertToAMPM(timeStr: any) {
	// Convert input string to Date object
	const timeObj = new Date(`2000-01-01T${timeStr}:00`);

	// Format the Date object in the desired AM/PM format
	const ampmTime = timeObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

	return ampmTime;
}
// //@ts-ignore
// export function calculateWorkingHours(checkIn, checkOut) {
// 	// Convert check-in and check-out times to minutes since midnight
// 	const checkInMinutes = convertToMinutes(checkIn);
// 	const checkOutMinutes = convertToMinutes(checkOut);

// 	// Calculate the difference in minutes
// 	let difference = checkOutMinutes - checkInMinutes;

// 	// Handle cases where check-out time is earlier than check-in time (e.g., overnight shifts)
// 	if (difference < 0) {
// 		difference += 24 * 60; // Add 24 hours in minutes
// 	}

// 	// Convert the total minutes back to hours and minutes
// 	const totalHours = Math.floor(difference / 60);
// 	const totalMinutes = difference % 60;

// 	return { hours: totalHours, minutes: totalMinutes };
// }

// // Function to convert time string (e.g., '1:30 AM' or '8:00 PM') to minutes since midnight
// //@ts-ignore
// function convertToMinutes(time) {
// 	if (!time) {
// 		return 0; // or any other default value, depending on your requirements
// 	}

// 	const [hourMinute, period] = time.split(' ');
// 	const [hour, minute] = hourMinute.split(':').map(Number);
// 	let totalMinutes = hour * 60 + minute;
// 	if (period === 'PM' && hour !== 12) {
// 		totalMinutes += 12 * 60; // Add 12 hours in minutes for PM times (except 12 PM)
// 	}
// 	return totalMinutes;
// }

export function calculateWorkingHours(checkIn, checkOut) {
    // Convert check-in time to minutes since midnight
    const checkInMinutes = convertToMinutes(checkIn);

    // If no check-out time is provided, use the current time
    const checkOutMinutes = checkOut ? convertToMinutes(checkOut) : getCurrentTimeInMinutes();

    // If there's no check-in, return zero hours worked
    if (checkInMinutes === 0) {
        return { hours: 0, minutes: 0 };
    }

    // Calculate the difference in minutes
    let difference = checkOutMinutes - checkInMinutes;

    // Handle cases where check-out time is earlier than check-in time (e.g., overnight shifts)
    if (difference < 0) {
        difference += 24 * 60; // Add 24 hours in minutes
    }

    // Convert the total minutes back to hours and minutes
    const totalHours = Math.floor(difference / 60);
    const totalMinutes = difference % 60;

    return { hours: totalHours, minutes: totalMinutes };
}

function convertToMinutes(time) {
    if (!time) {
        return 0; // Return 0 for no time input
    }

    const [hourMinute, period] = time.split(' ');
    const [hour, minute] = hourMinute.split(':').map(Number);
    let totalMinutes = hour * 60 + minute;

    if (period === 'PM' && hour !== 12) {
        totalMinutes += 12 * 60; // Convert PM times to 24-hour format
    } else if (period === 'AM' && hour === 12) {
        totalMinutes -= 12 * 60; // Convert 12 AM to 0 hours
    }

    return totalMinutes;
}

function getCurrentTimeInMinutes() {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();
    return currentHour * 60 + currentMinute;
}


// function convertToMinutes(time) {
// 	if (!time) {
// 		return 0; // or any other default value, depending on your requirements
// 	}

// 	const [hourMinute, period] = time.split(' ');
// 	const [hour, minute] = hourMinute.split(':').map(Number);
// 	let totalMinutes = hour * 60 + minute;
// 	if (period === 'PM' && hour !== 12) {
// 		totalMinutes += 12 * 60; // Add 12 hours in minutes for PM times (except 12 PM)
// 	}
// 	return totalMinutes;
// }

//@ts-ignore
export function convertToISODate(inputObject) {
	//@ts-ignore
	const convertDate = (dateString) => {
		const months = {
			Jan: '01',
			Feb: '02',
			Mar: '03',
			Apr: '04',
			May: '05',
			Jun: '06',
			Jul: '07',
			Aug: '08',
			Sep: '09',
			Oct: '10',
			Nov: '11',
			Dec: '12',
		};

		const [month, day, year] = dateString.split(' ');
		//@ts-ignore
		return `${year}-${months[month]}-${day.slice(0, -1).padStart(2, '0')}`;
	};

	return {
		start_date: convertDate(inputObject.startDate),
		end_date: convertDate(inputObject.endDate),
	};
}

export function convertTo24HourFormat(time: any) {
	// Split the time string into hours, minutes, and AM/PM
	const [timeStr, meridian] = time.split(' ');

	// Split the hours and minutes
	const [hoursStr, minutesStr] = timeStr.split(':');

	// Convert hours to integer
	let hours = parseInt(hoursStr, 10);

	// If it's PM and not already 12 PM, add 12 hours
	if (meridian === 'PM' && hours !== 12) {
		hours += 12;
	}

	// If it's AM and 12 AM, set hours to 0
	if (meridian === 'AM' && hours === 12) {
		hours = 0;
	}

	// Convert hours and minutes to 2-digit format
	const formattedHours = String(hours).padStart(2, '0');
	const formattedMinutes = String(minutesStr).padStart(2, '0');

	// Return the time in 24-hour format
	return `${formattedHours}:${formattedMinutes}`;
}

export const isPathInMenu = (path, menu) => {
	return Object.values(menu).some((submenu) => {
		return Object.values(submenu).some((item) => {
			//@ts-ignore
			return Object.values(item)?.some((subItem) => subItem?.path === path);
		});
	});
};
