import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	addDeviceService,
	connectDeviceService,
	deleteDeviceService,
	disconnectDeviceService,
	getDeviceService,
	updateDeviceService,
} from '../../../services/services';

// Add device
export const addDevice = createAsyncThunk('addDevice', async (data, thunkAPI) => {
	try {
		const response = await addDeviceService(data);
		return response?.data;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

// get devices
export const getDevices = createAsyncThunk('getDevices', async (_, thunkAPI) => {
	try {
		const response = await getDeviceService();
		return response?.data;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

// update
export const updateDevice = createAsyncThunk('updateDevice', async (credentials, thunkAPI) => {
	try {
		const { id, ...data } = credentials;
		const response = await updateDeviceService(id, data);
		return response?.data;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

//delete
export const deleteDevice = createAsyncThunk('deleteDevice', async (id, thunkAPI) => {
	try {
		const response = await deleteDeviceService(id);
		return response?.data;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

// device connect
export const deviceConnection = createAsyncThunk('connectDevice', async(device, thunkAPI) => {
	try {
		const response = await connectDeviceService(device);
		return response?.data;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
})
// device disconnect
export const deviceDisconnect = createAsyncThunk('disconnectDevice', async(device, thunkAPI) => {
	try {
		const response = await disconnectDeviceService(device);
		return response?.data;
	} catch (error) {    
		return thunkAPI.rejectWithValue(error);
	}
})     

const deviceSlice = createSlice({
	name: 'deviceSlice',
	initialState: {
		devices: null,
		update: null,
		deletedDevice: null,
		connectDevices: null,
		error: null,
		isLoading: false,
	},
	reducers: {
		resetDeviceState: (state) => {
			state.devices = [];
			state.connectDevices = null;
			state.isLoading = false;
			state.error = null;
		},
		resetConnectedDeviceState: (state) => {
			state.connectDevices = null;
			state.isLoading = false;
			state.error = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(addDevice.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(addDevice.fulfilled, (state, action) => {
				state.isLoading = false;
				state.devices = action.payload;
				state.error = null;
			})
			.addCase(addDevice.rejected, (state, action) => {
				state.isLoading = false;
				state.devices = null;
				state.error = action.payload;
			})

			// for getting the devices
			.addCase(getDevices.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(getDevices.fulfilled, (state, action) => {
				state.isLoading = false;
				state.devices = action.payload;
				state.error = null;
			})
			.addCase(getDevices.rejected, (state, action) => {
				state.isLoading = false;
				state.devices = null;
				state.error = action.payload;
			})

			// updating
			.addCase(updateDevice.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(updateDevice.fulfilled, (state, action) => {
				state.isLoading = false;
				state.update = action.payload;
				state.error = null;
			})
			.addCase(updateDevice.rejected, (state, action) => {
				state.isLoading = false;
				state.update = null;
				state.error = action.payload;
			})

			//deleting
			.addCase(deleteDevice.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(deleteDevice.fulfilled, (state, action) => {
				state.isLoading = false;
				state.deletedDevice = action.payload;
                state.error = null;
			})
			.addCase(deleteDevice.rejected, (state, action) => {
				state.isLoading = false;
                state.deletedDevice = null;
                state.error = action.payload;
			})

			// deviceConnection
			.addCase(deviceConnection.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(deviceConnection.fulfilled, (state, action) => {
				state.isLoading = false;
				state.connectDevices = action.payload;
                state.error = null;
			})
			.addCase(deviceConnection.rejected, (state, action) => {
				state.isLoading = false;
                state.connectDevices = null;
                state.error = action.payload;
			})

			// deviceDisconnect
			.addCase(deviceDisconnect.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(deviceDisconnect.fulfilled, (state, action) => {
				state.isLoading = false;
				state.connectDevices = action.payload;
                state.error = null;
			})
			.addCase(deviceDisconnect.rejected, (state, action) => {
				state.isLoading = false;
                state.error = action.payload;
			})
	},
});

export const { resetDeviceState, resetConnectedDeviceState } = deviceSlice.actions;
export default deviceSlice.reducer;
