import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../../components/icon/Icon';
import Logo from '../../components/Logo';

interface IBrandProps {
	asideStatus: boolean;
	setAsideStatus(...args: unknown[]): unknown;
}
const Brand: FC<IBrandProps> = ({ asideStatus, setAsideStatus }) => {
	const logoStyles = {
		width: '43%', // Set the logo's width to 50%
		maxWidth: '140px',
		// overflow:'hidden' // Optional: set a maximum width to maintain the aspect ratio
	};
	return (
		<div className='brand'>
			<div className='brand-logo'>
				<h1 className='brand-title d-flex '>
					<Link to='/' aria-label='Logo'>
						<div style={logoStyles}>
							<Logo height={15} width={140} />
						</div>

						{/* <img src="./logo-light.png" alt="" /> */}
					</Link>
				</h1>
			</div>
			<button
				type='button'
				className='btn brand-aside-toggle'
				aria-label='Toggle Aside'
				onClick={() => setAsideStatus(!asideStatus)}>
				<Icon icon='FirstPage' className='brand-aside-toggle-close' />
				<Icon icon='LastPage' className='brand-aside-toggle-open' />
			</button>
		</div>
	);
};
Brand.propTypes = {
	asideStatus: PropTypes.bool.isRequired,
	setAsideStatus: PropTypes.func.isRequired,
};

export default Brand;
