// import React from 'react';
// import { DotLoader } from 'react-spinners';
// import './loader.css'; // Import your custom CSS for styling
// // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-expressions
// const Loader = ({ isLoading }) => {
// 	return (
// 		<div className={`loader-overlay ${isLoading ? 'visible' : 'hidden'}`}>
// 			<div className='loader-container'>
// 				<DotLoader color='#36D7B7' loading={isLoading} size={150} />
// 			</div>
// 		</div>
// 	);
// };

// export default Loader;

// import React from 'react';
// import './loader.css';
// import { PropagateLoader } from 'react-spinners';
// import Logo from "./../../assets/img/logo-light.png";

// const Loader = ({ isLoading, text = "Loading...", color = "#36D7B7" }) => {
//     return (
//         <div className={`loader-overlay ${isLoading ? 'visible' : 'hidden'}`}>
//             <div className='loader-container'>
//                 <img src={Logo} alt="Company Logo" className='loader-logo' />
//                 <div className='loading-text'>{text}</div>
//                 <PropagateLoader color={color} loading={isLoading} />
//             </div>
//         </div>
//     );
// };

// export default Loader;

import React, { useEffect, useState } from 'react';
import './loader.css';
import { PropagateLoader } from 'react-spinners';
import Logo from './../../assets/img/logo-light.png';

import PropTypes from 'prop-types'; // Make sure it's imported

const Loader = ({ isLoading, text = 'Loading...', color = '#36D7B7', delay = 500 }) => {
	const [visible, setVisible] = useState(isLoading);

	useEffect(() => {
		if (isLoading) {
			setVisible(true);
		} else {
			const timer = setTimeout(() => {
				setVisible(false);
			}, delay);

			return () => clearTimeout(timer);
		}
	}, [isLoading, delay]);

	return (
		<div className={`loader-overlay ${visible ? 'visible' : 'hidden'}`}>
			<div className='loader-container'>
				<img src={Logo} alt='Company Logo' className='loader-logo' />
				<div className='loading-text'>{text}</div>
				<PropagateLoader color={color} />
			</div>
		</div>
	);
};

Loader.propTypes = {
	isLoading: PropTypes.bool.isRequired, // Set to isRequired if it's a required prop
	text: PropTypes.string,
	color: PropTypes.string,
	delay: PropTypes.number,
};

export default Loader;
