import React, { useContext } from 'react';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import Navigation from '../../../layout/Navigation/Navigation';
import { pageLayoutTypesPagesMenu, superAdminPageLayoutTypesPagesMenu } from '../../../menu';
import useDeviceScreen from '../../../hooks/useDeviceScreen';
import Popovers from '../../../components/bootstrap/Popovers';
import AuthContext from '../../../contexts/authContext';
import { useSelector } from 'react-redux';
// import './../../presentation/page-layouts/categories/categories.css';

const DefaultHeader = () => {
	const { width } = useDeviceScreen();
	const currentEmployee = useSelector((state: any) => state.employee?.currentEmployee?.data?.employee?.employee_name);
	const firstChar = currentEmployee?.charAt(0)?.toLocaleUpperCase();

	// const menu =
	// 	userData.is_super_admin == 'Y'
	// 		? { ...superAdminPageLayoutTypesPagesMenu }
	// 		: { ...pageLayoutTypesPagesMenu };

	return (
		<Header>
			<HeaderLeft>
				{/* <Navigation
					menu={{ ...menu }}
					id='header-top-menu'
					horizontal={
						!!width && width >= Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
					}
					isHeader={true}
				/> */}
				Convier Solutions Management System
			</HeaderLeft>
			<HeaderRight>
				{/* <div className='user-circle'>
					<span className='initials'>{firstChar}</span>
				</div> */}
	 <Popovers
					title={firstChar || ""}
					desc={currentEmployee}>
				{firstChar||""}
				</Popovers>
			</HeaderRight>
		</Header>
	);
};

export default DefaultHeader;
