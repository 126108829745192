import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getPermissionsService } from '../../../services/services';

export const getPermissions = createAsyncThunk('getPermissions', async (credentials, thunkAPI) => {
	try {
		const response = await getPermissionsService();
		return response.data;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

const permissionsSlice = createSlice({
	name: 'permissionsSlice',
	initialState: {
		permissions: null,
		isLoading: false,
		error: null,
	},

	extraReducers: (builder) => {
		builder
			.addCase(getPermissions.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(getPermissions.fulfilled, (state, action) => {
				state.isLoading = false;
				state.permissions = action.payload;
				state.error = null;
			})
			.addCase(getPermissions.rejected, (state, action) => {
				state.isLoading = false;
				state.permissions = null;
				state.error = action.payload;
			});
	},
});

export default permissionsSlice.reducer;
