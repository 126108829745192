import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	addRoleService,
	getRoleService,
	inactiveRoleService,
	updateRoleService,
} from '../../../services/services';

export const addRole = createAsyncThunk('addRole', async (credentials, thunkAPI) => {
	try {
		const response = await addRoleService(credentials);
		return response.data;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
export const getRoles = createAsyncThunk('getRoles', async (credentials, thunkAPI) => {
	try {
		const response = await getRoleService(credentials);

		return response.data;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const updateRoles = createAsyncThunk('updateRoles', async (credentials, thunkAPI) => {
	try {
		const { id, ...reset } = credentials;

		const response = await updateRoleService(id, reset);

		return response.data;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const inactiveRoles = createAsyncThunk('inactiveRoles', async (credentials, thunkAPI) => {
	try {
		const { id, ...reset } = credentials;
		const response = await inactiveRoleService(id, reset);

		return response?.data;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
const roleSlice = createSlice({
	name: 'roleSlice',
	initialState: {
		role: null,
		isSubmitting: false,
		addingRoleError: null,
		roles: null,
		isLoading: false,
		error: null,
		update: null,
		isUpdating: false,
		updateError: null,
		inAtiveRole: null,
		isInactive: false,
	},
	reducers: {
		resetRole: (state) => {
			state.inAtiveRole = null;
			state.role = null;
			state.isSubmitting = false;
			state.addingRoleError = null;
			state.isLoading = false;
			state.error = null;
			state.update = null;
			state.isUpdating = null;
			state.updateError = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(addRole.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(addRole.fulfilled, (state, action) => {
				state.isLoading = false;
				state.role = action.payload;
				state.error = null;
			})
			.addCase(addRole.rejected, (state, action) => {
				state.isLoading = false;
				state.role = null;
				state.error = action.payload;
			});
		builder
			.addCase(updateRoles.pending, (state) => {
				state.isUpdating = true;
				state.updateError = null;
			})
			.addCase(updateRoles.fulfilled, (state, action) => {
				state.isUpdating = false;
				state.update = action.payload;
				state.updateError = null;
			})
			.addCase(updateRoles.rejected, (state, action) => {
				state.isUpdating = false;
				state.update = null;
				state.updateError = action.payload;
			});
		builder
			.addCase(getRoles.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(getRoles.fulfilled, (state, action) => {
				state.isLoading = false;
				state.roles = action.payload.data;
				state.error = null;
			})
			.addCase(getRoles.rejected, (state, action) => {
				state.isLoading = false;
				state.roles = null;
				state.error = action.payload;
			});
		builder
			.addCase(inactiveRoles.pending, (state) => {
				state.isInactive = true;
				state.error = null;
			})
			.addCase(inactiveRoles.fulfilled, (state, action) => {
				state.isInactive = false;
				state.inAtiveRole = action.payload;
				state.error = null;
			})
			.addCase(inactiveRoles.rejected, (state, action) => {
				state.isInactive = false;
				state.inAtiveRole = null;
				state.error = action.payload;
			});
	},
});

export const { resetRole } = roleSlice.actions;
export default roleSlice.reducer;
