import React, { lazy, useContext, useState } from 'react';
import {
	EmployeePagesMenu,
	dashboardPagesMenu,
	demoPagesMenu,
	pageLayoutTypesPagesMenu,
	superAdminPageLayoutTypesPagesMenu,
} from '../menu';
import Login from '../pages/presentation/auth/Login';
import AuthContext from '../contexts/authContext';
import path from 'path';
import { element } from 'prop-types';
const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};

const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),

	/**
	 * Employee ROUTES
	 */
	ADD_STORE: lazy(() => import('../pages/presentation/page-layouts/employees/AddEmployee')),
	LIST_STORES: lazy(() => import('../pages/presentation/page-layouts/employees/ListEmployees')),
	EDIT_Employee: lazy(() => import('../pages/presentation/page-layouts/employees/EditEmployee')),
	DETAIL_EMPLOYEE: lazy(
		() => import('../pages/presentation/page-layouts/employees/VeiwEmployeeDetail'),
	),

	/**
	 * Leave ROUTES
	 */

	ADD_PRODUCT: lazy(() => import('../pages/presentation/page-layouts/leaves/AddLeave')),
	LIST_PRODUCTS: lazy(() => import('../pages/presentation/page-layouts/leaves/ListLeaves')),
	LIST_LEAVE_BALANCE: lazy(
		() => import('../pages/presentation/page-layouts/leaves/LeaveBalance'),
	),
	LIST_EMP_LEAVES: lazy(() => import('../pages/presentation/page-layouts/leaves/MyRequestedLeaves')),
	EDIT_PRODUCT: lazy(() => import('../pages/presentation/page-layouts/leaves/EditLeave')),

	/**
	 * projects ROUTES
	 */

	ADD_REQUEST_CATEGORY: lazy(
		() => import('../pages/presentation/page-layouts/projects/AddProject'),
	),
	LIST_REQUEST_CATEGORIES: lazy(
		() => import('../pages/presentation/page-layouts/projects/ListProjects'),
	),
	LIST_EMPLOYEE_PROJECTS: lazy(
		() => import('../pages/presentation/page-layouts/projects/EmployeeProjectListing'),
	),
	EDIT_REQUEST_CATEGORY: lazy(
		() => import('../pages/presentation/page-layouts/projects/EditCategory'),
	),
	APPROVE_CATEGORIES_REQUEST: lazy(
		() => import('../pages/presentation/page-layouts/projects/ApproveCategories'),
	),

	/**
	 * Attendance ROUTES
	 */
	MARK_ATTENDANCE: lazy(
		() => import('../pages/presentation/page-layouts/attendance/MarkAttendance'),
	),
	LIST_ORDERS: lazy(() => import('../pages/presentation/page-layouts/attendance/ListAttendance')),
	ORDER_DETAIL: lazy(
		() => import('../pages/presentation/page-layouts/attendance/AttendanceDetail'),
	),
	EMPLOYEE_ATENDANCE: lazy(
		() => import('../pages/presentation/page-layouts/attendance/EmployeeAttendance'),
	),

	/**
	 * Roles ROUTES
	 */

	ADD_ROLE: lazy(() => import('../pages/presentation/page-layouts/role/AddRole')),
	LIST_ROLES: lazy(() => import('../pages/presentation/page-layouts/role/ListRole')),
	EDIT_ROLES: lazy(() => import('../pages/presentation/page-layouts/role/EditRole')),

	/**
	 * Device ROUTES
	 */

	ADD_DEVICE: lazy(() => import('../pages/presentation/page-layouts/device/AddDevice')),
	LIST_DEVICE: lazy(() => import('../pages/presentation/page-layouts/device/ListDevice')),

	/**
	 * CUSTTOMERS ROUTES
	 */

	LIST_CUSTOMERS: lazy(
		() => import('../pages/presentation/page-layouts/permissions/ListPermissions'),
	),

	/**
	 * PHARMACIES ROUTES
	 */

	UPDATE_PHARMACY_PROFILE: lazy(
		() => import('../pages/presentation/page-layouts/profile/UpdateProfile'),
	),

	LIST_PENDING_PHARMACIES: lazy(
		() => import('../pages/presentation/page-layouts/pharmacies/ListPendingPharmacies'),
	),

	LIST_APPROVED_PHARMACIES: lazy(
		() => import('../pages/presentation/page-layouts/pharmacies/ListApprovedPharmacies'),
	),

	/**
	 *  SUPER ADMIN PROFILE
	 */

	UPDATE_SUPER_ADMIN_PROFILE: lazy(
		() => import('../pages/presentation/page-layouts/profile/UpdateSuperAdminProfile'),
	),

	UPDATE_EMPLOYEE_PROFILE: lazy(
		() => import('../pages/presentation/page-layouts/profile/updateEmployeeProfile'),
	),

	// RADIAL_BAR: lazy(() => import('./../pages/presentation/dashboard/ChartRadialBarPage')),
};

// export const AdminRoutes = [
// 	{
// 		path: dashboardPagesMenu.dashboard.path,
// 		element: <LANDING.DASHBOARD />,
// 	},
// 	{
// 		path: demoPagesMenu.page404.path,
// 		element: <AUTH.PAGE_404 />,
// 	},
// 	{
// 		path: demoPagesMenu.login.path,
// 		element: <Login />,
// 	},
// 	{
// 		path: demoPagesMenu.signUp.path,
// 		element: <Login isSignUp />,
// 	},
// 	{
// 		path: pageLayoutTypesPagesMenu.stores.subMenu.addStore.path,
// 		element: <PAGE_LAYOUTS.ADD_STORE />,
// 	},
// 	{
// 		path: pageLayoutTypesPagesMenu.stores.subMenu.listStores.path,
// 		element: <PAGE_LAYOUTS.LIST_STORES />,
// 	},
// 	{
// 		path: `${pageLayoutTypesPagesMenu.stores.subMenu.editStore.path}/:id`,
// 		element: <PAGE_LAYOUTS.EDIT_STORE />,
// 	},
// 	{
// 		path: pageLayoutTypesPagesMenu.orders.subMenu.listOrders.path,
// 		element: <PAGE_LAYOUTS.LIST_ORDERS />,
// 	},
// 	{
// 		path: pageLayoutTypesPagesMenu.orders.subMenu.orderDetail.path,
// 		element: <PAGE_LAYOUTS.ORDER_DETAIL />,
// 	},
// 	{
// 		path: pageLayoutTypesPagesMenu.products.subMenu.addProduct.path,
// 		element: <PAGE_LAYOUTS.ADD_PRODUCT />,
// 	},
// 	{
// 		path: pageLayoutTypesPagesMenu.products.subMenu.listProducts.path,
// 		element: <PAGE_LAYOUTS.LIST_PRODUCTS />,
// 	},
// 	{
// 		path: `${pageLayoutTypesPagesMenu.products.subMenu.editProduct.path}/:id`,
// 		element: <PAGE_LAYOUTS.EDIT_PRODUCT />,
// 	},
// 	{
// 		path: pageLayoutTypesPagesMenu.categories.subMenu.requestAddCategory.path,
// 		element: <PAGE_LAYOUTS.ADD_REQUEST_CATEGORY />,
// 	},
// 	{
// 		path: pageLayoutTypesPagesMenu.categories.subMenu.listRequestCategories.path,
// 		element: <PAGE_LAYOUTS.LIST_REQUEST_CATEGORIES />,
// 	},
// 	{
// 		path: `${pageLayoutTypesPagesMenu.categories.subMenu.editCategory.path}/:id`,
// 		element: <PAGE_LAYOUTS.EDIT_REQUEST_CATEGORY />,
// 	},
// 	// {
// 	// 	path: superAdminPageLayoutTypesPagesMenu.categories.subMenu.approveRequest.path,
// 	// 	element: <PAGE_LAYOUTS.APPROVE_CATEGORIES_REQUEST />,
// 	// },
// 	// {
// 	// 	path: superAdminPageLayoutTypesPagesMenu.customers.path,
// 	// 	element: <PAGE_LAYOUTS.LIST_CUSTOMERS />,
// 	// },
// 	{
// 		path: pageLayoutTypesPagesMenu.pharmacy.path,
// 		element: <PAGE_LAYOUTS.UPDATE_PHARMACY_PROFILE />,
// 	},
// 	// {
// 	// 	path: superAdminPageLayoutTypesPagesMenu.pharmacies.subMenu.listPendingPharmacies.path,
// 	// 	element: <PAGE_LAYOUTS.LIST_PENDING_PHARMACIES />,
// 	// },
// 	// {
// 	// 	path: superAdminPageLayoutTypesPagesMenu.pharmacies.subMenu.listApprovedPharmacies.path,
// 	// 	element: <PAGE_LAYOUTS.LIST_APPROVED_PHARMACIES />,
// 	// },
// 	// {
// 	// 	path: superAdminPageLayoutTypesPagesMenu.profile.path,
// 	// 	element: <PAGE_LAYOUTS.UPDATE_SUPER_ADMIN_PROFILE />,
// 	// },
// 	// {
// 	// 	path: superAdminPageLayoutTypesPagesMenu.charts.path,
// 	// 	element: <PAGE_LAYOUTS.RADIAL_BAR />,
// 	// },
// ];

export const SuperAdminRoutes = [
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: demoPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: demoPagesMenu.login.path,
		element: <Login />,
	},
	// {
	// 	path: demoPagesMenu.signUp.path,
	// 	element: <Login isSignUp />,
	// },
	{
		path: superAdminPageLayoutTypesPagesMenu.categories.subMenu.listRequestCategories.path,
		element: <PAGE_LAYOUTS.LIST_REQUEST_CATEGORIES />,
	},
	{
		path: superAdminPageLayoutTypesPagesMenu.categories.subMenu.approveRequest.path,
		element: <PAGE_LAYOUTS.APPROVE_CATEGORIES_REQUEST />,
	},
	{
		path: superAdminPageLayoutTypesPagesMenu.customers.path,
		element: <PAGE_LAYOUTS.LIST_CUSTOMERS />,
	},

	{
		path: superAdminPageLayoutTypesPagesMenu.pharmacies.subMenu.listPendingPharmacies.path,
		element: <PAGE_LAYOUTS.LIST_PENDING_PHARMACIES />,
	},
	{
		path: superAdminPageLayoutTypesPagesMenu.pharmacies.subMenu.listApprovedPharmacies.path,
		element: <PAGE_LAYOUTS.LIST_APPROVED_PHARMACIES />,
	},
	{
		path: superAdminPageLayoutTypesPagesMenu.profile.path,
		element: <PAGE_LAYOUTS.UPDATE_SUPER_ADMIN_PROFILE />,
	},
	{
		path: superAdminPageLayoutTypesPagesMenu.profile.path,
		element: <PAGE_LAYOUTS.UPDATE_SUPER_ADMIN_PROFILE />,
	},
];
export const publicPages = [
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: demoPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: demoPagesMenu.login.path,
		element: <Login />,
	},
	{
		path: pageLayoutTypesPagesMenu.profile.path,
		element: <PAGE_LAYOUTS.UPDATE_SUPER_ADMIN_PROFILE />,
	},
];
export const auth = [
	{
		path: pageLayoutTypesPagesMenu.employee.subMenu.addEmployee.path,
		element: <PAGE_LAYOUTS.ADD_STORE />,
	},
	{
		path: pageLayoutTypesPagesMenu.employee.subMenu.listEmployees.path,
		element: <PAGE_LAYOUTS.LIST_STORES />,
	},
	{
		path: pageLayoutTypesPagesMenu.employee.subMenu.editEmployee.path,
		element: <PAGE_LAYOUTS.EDIT_Employee />,
	},
	{
		path: pageLayoutTypesPagesMenu.employee.subMenu.employeeDetail.path,
		element: <PAGE_LAYOUTS.DETAIL_EMPLOYEE />,
	},
	{
		path: pageLayoutTypesPagesMenu.attendance.subMenu.listAttendance.path,
		element: <PAGE_LAYOUTS.LIST_ORDERS />,
	},
	{
		path: pageLayoutTypesPagesMenu.attendance.subMenu.markAttendance.path,
		element: <PAGE_LAYOUTS.MARK_ATTENDANCE />,
	},

	{
		path: pageLayoutTypesPagesMenu.attendance.subMenu.attendanceDetail.path,
		element: <PAGE_LAYOUTS.ORDER_DETAIL />,
	},
	{
		path: pageLayoutTypesPagesMenu.leaves.subMenu.requestaddLeaves.path,
		element: <PAGE_LAYOUTS.ADD_PRODUCT />,
	},
	{
		path: pageLayoutTypesPagesMenu.leaves.subMenu.listRequestleaves.path,
		element: <PAGE_LAYOUTS.LIST_PRODUCTS />,
	},
	{
		path: pageLayoutTypesPagesMenu.leaves.subMenu.listleavesBalance.path,
		element: <PAGE_LAYOUTS.LIST_LEAVE_BALANCE />,
	},
	{
		path: `${pageLayoutTypesPagesMenu.leaves.subMenu.editleaves.path}/:id`,
		element: <PAGE_LAYOUTS.EDIT_PRODUCT />,
	},
	// {
	// 	path: pageLayoutTypesPagesMenu.projects.subMenu.requestAddProjects.path,
	// 	element: <PAGE_LAYOUTS.ADD_REQUEST_CATEGORY />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.projects.subMenu.listRequestProjects.path,
	// 	element: <PAGE_LAYOUTS.LIST_REQUEST_CATEGORIES />,
	// },
	// {
	// 	path: `${pageLayoutTypesPagesMenu.projects.subMenu.editProjects.path}/:id`,
	// 	element: <PAGE_LAYOUTS.EDIT_REQUEST_CATEGORY />,
	// },
	// {
	// 	path: superAdminPageLayoutTypesPagesMenu.categories.subMenu.approveRequest.path,
	// 	element: <PAGE_LAYOUTS.APPROVE_CATEGORIES_REQUEST />,
	// },
	// {
	// 	path: superAdminPageLayoutTypesPagesMenu.customers.path,
	// 	element: <PAGE_LAYOUTS.LIST_CUSTOMERS />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.pharmacy.path,
	// 	element: <PAGE_LAYOUTS.UPDATE_PHARMACY_PROFILE />,
	// },
	// {
	// 	path: superAdminPageLayoutTypesPagesMenu.customers.path,
	// 	element: <PAGE_LAYOUTS.LIST_CUSTOMERS />,
	// },
	// {
	// 	path: dashboardPagesMenu.dashboard.path,
	// 	element: <LANDING.DASHBOARD />,
	// },
	// {
	// 	path: demoPagesMenu.page404.path,
	// 	element: <AUTH.PAGE_404 />,
	// },

	{
		path: pageLayoutTypesPagesMenu.attendance.subMenu.EmplistAttendance.path,
		element: <PAGE_LAYOUTS.EMPLOYEE_ATENDANCE />,
	},
	{
		path: pageLayoutTypesPagesMenu.leaves.subMenu.requestaddLeaves.path,
		element: <PAGE_LAYOUTS.ADD_PRODUCT />,
	},
	{
		path: pageLayoutTypesPagesMenu.leaves.subMenu.employeeRequestedLeaves.path,
		element: <PAGE_LAYOUTS.LIST_EMP_LEAVES />,
	},
	{
		path: EmployeePagesMenu.projects.subMenu.listEmployeeProjects.path,
		element: <PAGE_LAYOUTS.LIST_EMPLOYEE_PROJECTS />,
	},
	{
		path: EmployeePagesMenu.profile.path,
		element: <PAGE_LAYOUTS.UPDATE_EMPLOYEE_PROFILE />,
	},

	{
		path: pageLayoutTypesPagesMenu.roles.subMenu.addRole.path,
		element: <PAGE_LAYOUTS.ADD_ROLE />,
	},
	{
		path: pageLayoutTypesPagesMenu.roles.subMenu.listRole.path,
		element: <PAGE_LAYOUTS.LIST_ROLES />,
	},
	{
		path: pageLayoutTypesPagesMenu.roles.subMenu.editRole.path,
		element: <PAGE_LAYOUTS.EDIT_ROLES />,
	},
	{
        path: pageLayoutTypesPagesMenu.device.subMenu.addDevice.path,
        element: <PAGE_LAYOUTS.ADD_DEVICE/>
    },
	{
        path: pageLayoutTypesPagesMenu.device.subMenu.listDevice.path,
        element: <PAGE_LAYOUTS.LIST_DEVICE/>
    },
];

export const EmployeePanel = [
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: demoPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: demoPagesMenu.login.path,
		element: <Login />,
	},
	// {
	// 	path: demoPagesMenu.signUp.path,
	// 	element: <Login isSignUp />,
	// },
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: demoPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},

	{
		path: pageLayoutTypesPagesMenu.attendance.subMenu.listAttendance.path,
		element: <PAGE_LAYOUTS.EMPLOYEE_ATENDANCE />,
	},
	{
		path: pageLayoutTypesPagesMenu.leaves.subMenu.requestaddLeaves.path,
		element: <PAGE_LAYOUTS.ADD_PRODUCT />,
	},
	{
		path: pageLayoutTypesPagesMenu.leaves.subMenu.listRequestleaves.path,
		element: <PAGE_LAYOUTS.LIST_EMP_LEAVES />,
	},
	{
		path: EmployeePagesMenu.projects.subMenu.listEmployeeProjects.path,
		element: <PAGE_LAYOUTS.LIST_EMPLOYEE_PROJECTS />,
	},
	{
		path: EmployeePagesMenu.profile.path,
		element: <PAGE_LAYOUTS.UPDATE_EMPLOYEE_PROFILE />,
	},
];

// const contents = [...presentation];

// export default contents;
